<template>
  <div class="container-fluid mb-2 header-sticky">
    <header :class="!isUser ? 'container-fluid' : 'user_login container-fluid'">
      <!-- Mobile Navigation -->

      <!-- Desktop Navigation -->
      <nav class="nav">
        <ul
          class="d-flex justify-content-between align-items-center textRight nav__list w-100"
        >
          <a :href="`#`" title="abc" class="logo hidden-w-1300">
            <h1>Abc</h1>
          </a>

          <!-- Location Search  -->

          <div class="col-xl-3 col-md-4 hidden-w-1300">
            <div class="fieldFloating d-flex">
              <v-select
                ref="customSelect"
                :options="addresses"
                @input="locationUpdate(location)"
                v-model="location"
                :clearable="false"
                style="width: 90%; border: white; background: white; padding: 0px 10px"
                class="custom-input-select"
              >
              </v-select>

              <div class="search-icon" @click="openCustomSelect">
                <img
                  :src="`${$store.state.common.publicPath}img/magnifying-glass-solid (1).svg`"
                  alt="Search"
                  style="height: 17px; width: 17px"
                />
              </div>

              <!-- <img :src="imageIcon" alt=""> -->
            </div>
          </div>

          <!-- Navbar Links -->
          <div class="nav-links hidden-w-1300">
            <ul>
              <li @click="openUrl('#')">Home</li>
              <li @click="openUrl('#')">Enterprise</li>
              <li @click="openUrl('#')">About Us</li>
              <li @click="openUrl('#')">Book a Tour</li>
            </ul>
          </div>

          <!-- Contact Us Button -->
          <div class="contact-us hidden-w-1300">
            <button @click="openUrl('#')">Contact Us</button>
          </div>
        </ul>

        <nav :class="{ 'nav-open': isNavOpen }">
          <ul
            class="d-flex justify-content-between align-items-center textRight nav__list w-100"
          >
            <a :href="`#`" title="abc" class="logo hamburger">
              <h1>Abc</h1>
            </a>
            <div class="col-xl-3 col-md-4 hamburger-remove hamburger-remove-xs-hide">
              <div class="fieldFloating d-flex">
                <v-select
                  ref="customSelectTab"
                  :options="addresses"
                  @input="locationUpdate(location)"
                  v-model="location"
                  :clearable="false"
                  style="width: 90%; border: white; background: white; padding: 0px 10px"
                  class="custom-input-select"
                >
                </v-select>

                <div class="search-icon" @click="openCustomSelectTab">
                  <img
                    :src="`${$store.state.common.publicPath}img/magnifying-glass-solid (1).svg`"
                    alt="Search"
                    style="height: 17px; width: 17px"
                  />
                </div>
              </div>
            </div>

            <button class="hamburger" @click="toggleNav">
              <span v-if="!isNavOpen">☰</span>
              <span v-if="isNavOpen">✖</span>
            </button>
            <ul v-if="isNavOpen" class="mob-nav-links">
              <div class="">
                <div class="fieldFloating d-flex"></div>
              </div>
              <li @click="toggleNav"><a href="#" target="_blank">Home</a></li>
              <li @click="toggleNav"><a href="#" target="_blank">Enterprise</a></li>
              <li @click="toggleNav"><a href="#" target="_blank">About Us</a></li>
              <li @click="toggleNav"><a href="#" target="_blank">Book a Tour</a></li>
              <li @click="toggleNav"><a href="#" target="_blank">Contact Us</a></li>
            </ul>
          </ul>
          <div class="col-xl-3 col-md-4 hamburger-remove-xs">
            <div class="fieldFloating d-flex">
              <v-select
                ref="customSelectMobile"
                :options="addresses"
                @input="locationUpdate(location)"
                v-model="location"
                :clearable="false"
                style="width: 90%; border: white; background: white; padding: 0px 10px"
                class="custom-input-select"
              >
              </v-select>

              <div class="search-icon" @click="openCustomSelectMobile">
                <img
                  :src="`${$store.state.common.publicPath}img/magnifying-glass-solid (1).svg`"
                  alt="Search"
                  style="height: 17px; width: 17px"
                />
              </div>
            </div>
          </div>
        </nav>
      </nav>
    </header>
    <div v-if="showRefinePopup" class="popup-overlay">
      <div class="popup-content">
        <button type="button" class="popup-close" @click="closeRefinePopup">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="popup-title">Refine your office search</h3>
        <!-- <form @submit.prevent="submitForm" class="popup-form">
        <div class="form-group">
          <label for="name" class="form-label">Name:</label>
          <input type="text" id="name" v-model="form.name" class="form-input" required />
        </div>
        <div class="form-group">
          <label for="email" class="form-label">Email:</label>
          <input type="email" id="email" v-model="form.email" class="form-input" required />
        </div>
        <div class="form-group">
          <label for="phone" class="form-label">Phone:</label>
          <input type="text" id="phone" v-model="form.phone" class="form-input" required />
        </div>
        <button type="submit" class="form-submit">Submit</button>
        <button type="button" class="form-close" @click="closeSchedulePopup">Close</button>
      </form> -->

        <div class=" " style="margin-bottom: 20px">
          <div v-click-outside="hideSlider">
            <div class="row">
              <div class="col-md-12">
                <div class="search__field fieldFloating border-add">
                  <input
                    type="text"
                    max="500"
                    min="1"
                    placeholder=" "
                    :value="headCount"
                    readonly="true"
                    class="formControl validate_number"
                    @click="isSlider = true"
                  />

                  <label class="fieldFloating__label">Max Capacity</label>
                </div>
              </div>
            </div>
            <div class="search__field fieldFloating minmaxslider border-add">
              <vue-slider
                v-if="isSlider"
                v-model="headCount"
                v-bind="options"
                :max="500"
                :min="0"
                class="formControl"
              ></vue-slider>
            </div>
          </div>
          <div style="margin-top: 70px">
            <div class="fieldFloating border-add">
              <div class="d-flex">
                <datepicker
                  ref="picker"
                  @focusout.native="close"
                  class="formControl"
                  v-model="start_date"
                  @focusin.native="getStartDate"
                  :disabledDates="disabledDates"
                >
                </datepicker>

                <button v-if="start_date" class="date-reset" @click="removeDate()">
                  X
                </button>
              </div>
              <label
                :class="
                  start_date
                    ? 'fieldFloating__label date_selected'
                    : 'fieldFloating__label date_open'
                "
                >Start Date</label
              >
            </div>
          </div>
        </div>

        <center><button class="submit-refine">Submit</button></center>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapMutations } from "vuex";
import { regUser, forgotPassword, getUserforUpdate } from "@/api/user";
import { getLocations } from "@/api/properties";
import { required, minLength, email } from "vuelidate/lib/validators";
import ContectUsPopUp from "@/components/frontend/ContectUsPopUp.vue";
import autocomplete from "../../views/client/AutoComplete.vue";
import Datepicker from "vuejs-datepicker";
import VueSlider from "vue-slider-component";
import Firebase from "firebase";
import swal from "sweetalert";
// import imageIcon from '../../assets/Images/magnifying-glass-solid (1)';

export default {
  name: "Header",
  components: {
    ContectUsPopUp,
    Datepicker,
    VueSlider,
    autocomplete,
    vSelect,
  },
  data() {
    return {
      baseAPI: process.env.VUE_APP_BASE_API + process.env.VUE_APP_PROFILE_PATH,
      isNavOpen: false,
      loginForm: {
        username: "", //admin@gmail.com(admin)
        password: "", //123456
      },
      signUpForm: {
        email: "",
        password: "",
        name: "",
        profile_image: "",
      },
      showPassword: false,
      loading: false,
      isLogin: false,
      isForgotPwd: false,
      isSignUp: false,
      emailValidate: "",
      isExplore: false,
      userEmail: "",
      hash: "",
      regValidate: false,
      loginValidate: false,
      emailValid: false,
      isSubmitting: false,
      isSubmittingLogin: false,
      isSubmittingReg: false,
      registrationSuccessful: false,
      showRefinePopup: false,
      location: this.$store.state.location.currentLocation
        ? this.$store.state.location.currentLocation
        : "Bellevue, WA, US",
      // selectedAddress: "Bellevue Square, Bellevue, WA, USA",
      // addresses: [
      //   "Seattle, WA, USA",
      //   "Bellevue, WA, USA",
      //   "Sunnyvale, CA, USA",
      //   "Upland, CA, USA",
      //   "Redmond, WA, USA",
      //   "Rancho Cucamonga, CA, USA",
      //   "London Mills, IL, USA",
      //   "Prospect, VA, USA",
      //   "Green Cove Springs, FL, USA",
      //   "Washington, DC, USA"
      // ]
      addresses: [],
    };
  },
  created() {
    this.hash = this.$route.params.hash;
    document.addEventListener("click", this.documentClick);
  },
  computed: {
    ...mapGetters(["role", "name", "user"]),
    urlPath() {
      return `${this.$store.state.common.publicPath}admin`;
    },
    isAdmin() {
      return this.role === "admin";
    },
    isUser() {
      return this.user && this.user.name;
    },
    isSearchRequired() {
      return (
        this.$route.path == "/" ||
        this.$route.path.includes("detail") ||
        this.$route.path.includes("list") ||
        this.$route.path.includes("contracts") ||
        this.$route.path.includes("profile")
      );
    },
    showBlackFont() {
      return this.$route.path == "/" || this.$route.path.includes("detail")
        ? true
        : false;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    inputType() {
      return this.showPassword ? "text" : "password";
    },
  },
  mounted() {
    this.fetchUniqueAddresses();
    this.$root.$on("getSignup", () => {
      this.isExplore = true;
      setTimeout(() => {
        this.openModal(false, true, true);
      }, 0);
    });
    this.$root.$on("getLogin", () => {
      this.isExplore = true;
      setTimeout(() => {
        this.openModal(true, false, true);
      }, 0);
    });
    let redirectTo = this.$route.query.redirectTo;
    if (redirectTo) {
      this.$notify({
        group: "notify",
        type: "error",
        text: "Please Login First",
      });
      this.openModal(true, false);
    }
  },
  updated() {
    if (!this.$router.params) {
      this.hash = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),

    openCustomSelect() {
      if (
        this.$refs.customSelect &&
        typeof this.$refs.customSelect.toggleDropdown === "function"
      ) {
        const mockEvent = {
          target: this.$refs.customSelect.$el, // Mocking the target element
          preventDefault: () => {}, // Mocking preventDefault as an empty function
        };
        this.$refs.customSelect.toggleDropdown(mockEvent);
      } else {
        console.error("Dropdown is not available or toggleDropdown is not a function");
      }
    },

    openCustomSelectTab() {
      if (
        this.$refs.customSelectTab &&
        typeof this.$refs.customSelectTab.toggleDropdown === "function"
      ) {
        const mockEvent = {
          target: this.$refs.customSelectTab.$el, // Mocking the target element
          preventDefault: () => {}, // Mocking preventDefault as an empty function
        };
        this.$refs.customSelectTab.toggleDropdown(mockEvent);
      } else {
        console.error("Dropdown is not available or toggleDropdown is not a function");
      }
    },

    openCustomSelectMobile() {
      if (
        this.$refs.customSelectMobile &&
        typeof this.$refs.customSelectMobile.toggleDropdown === "function"
      ) {
        const mockEvent = {
          target: this.$refs.customSelectMobile.$el, // Mocking the target element
          preventDefault: () => {}, // Mocking preventDefault as an empty function
        };
        this.$refs.customSelectMobile.toggleDropdown(mockEvent);
      } else {
        console.error("Dropdown is not available or toggleDropdown is not a function");
      }
    },

    openSelect() {
      this.$refs.selectDropdown.focus(); // Focus the select element
      // this.$refs.selectDropdown.click(); // Simulate a click event
      setTimeout(() => {
        const event = new MouseEvent("mousedown", { bubbles: true });
        this.$refs.selectDropdown.dispatchEvent(event); // Simulate a mousedown event
      }, 0);
      console.log("selectDropdown ==============>>>>>>>>>>>", this.$refs.selectDropdown);
    },
    getLocationFromStore() {
      let loc = this.$store.state.location.currentLocation;
      console.log("LOC", loc);
      return loc;
    },
    async fetchUniqueAddresses() {
      await getLocations()
        .then((res) => {
          console.log("res ========= >>>>>>>>>>>>", res);
          if (res.data.data && res.data.data.length > 0) {
            this.addresses = res?.data?.data;
          } else {
            this.addresses = [];
          }
        })
        .catch((error) => {
          console.error(error);
          this.addresses = [];
        });
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    toggleRefinePopUp(show) {
      this.showRefinePopup = show;
      // console.log("show", show)
    },
    closeRefinePopup() {
      this.showRefinePopup = false;
    },
    locationUpdate(location) {
      this.$store.dispatch("location/updateLocation", location);
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    AuthProvider() {
      const provider = new Firebase.auth.GoogleAuthProvider();
      Firebase.auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          var token = credential.accessToken;
          var user = result.user;
          var userData = {
            name: user.displayName,
            email: user.email,
            username: user.email,
            image: user.photoURL,
            accessToken: token,
            refreshToken: user.refreshToken,
            provider: "google",
          };
          // console.log("user info", userData);
          let redirectTo = this.$router.history.current.query.redirectTo;
          this.loading = true;
          this.$store
            .dispatch("user/social_login", userData)
            .then(() => {
              this.$notify({
                group: "notify",
                type: "success",
                text: "Login successfully",
              });
              if (redirectTo) {
                this.$router.push({ path: redirectTo });
              }
              this.openModal();
              this.loading = false;
              this.isSubmittingLogin = false;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
              // this.$notify({
              //     group: "notify",
              //     type: "error",
              //     text: err.response.data.error,
              // });
              this.isSubmittingLogin = false;
            });
          let idToken;
          await Firebase.auth()
            .currentUser.getIdToken(true)
            .then((res) => (idToken = res));
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    },
    termsService() {
      swal("We do not share or use your contact to any other organization of purpose.");
    },
    whyLogin() {
      return;
      swal("We do not share or use your contact to any other organization of purpose.");
    },
    SocialLogin(provider, response) {
      this.$http
        .post("/sociallogin/" + provider, response)
        .then((response) => {
          // console.log(response.data);
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },
    hideModals() {
      // console.log("Asd");
      this.isSignUp = false;
      this.isForgotPwd = false;
      this.isLogin = false;
    },
    closeModal(modal) {
      if (modal === "signup") {
        this.isSignUp = false;
        this.showPassword = false;
        this.regValidate = false;
        this.emailValidate = false;
      }
      if (modal === "login") {
        this.isLogin = false;
        this.showPassword = false;
      }
      if (modal === "forgot") {
        this.isForgotPwd = false;
      }
    },
    documentClick(e) {
      var outside1 = true;
      var outside2 = true;
      var outside3 = true;

      var path = e.path || (e.composedPath && e.composedPath());

      // var outside4 = true
      if (path && path != undefined) {
        for (var i = 0; i < path.length - 1; ++i) {
          if (
            path[i].className != undefined &&
            typeof path[i].className.includes == "function"
          ) {
            if (
              path[i].className.includes("forgot-modal") ||
              path[i].className.includes("click-here")
            ) {
              outside1 = false;
              break;
            }
            if (
              path[i].className.includes("forLogin") ||
              path[i].className.includes("login")
            ) {
              outside2 = false;
              break;
            }
            if (
              path[i].className.includes("forSignup") ||
              path[i].className.includes("signup")
            ) {
              outside3 = false;
              break;
            }
            // if (
            //   e.path[i].className.includes("contectUs")
            // ) {
            //   outside4 = false;
            //   break;
            // }
          }
        }
      }
      if (outside1) {
        this.isForgotPwd = false;
        this.userEmail = "";
        this.emailValid = false;
        let body = document.body;
        body.classList.toggle("is-modalOpen");
      }
      if (outside2) {
        // this.isLogin = false;
        this.loginForm = {};
        this.loginValidate = false;
        let body = document.body;
        body.classList.toggle("is-modalOpen");
      }
      if (outside3) {
        // this.isSignUp = false;
        this.signUpForm = {};
        this.regValidate = false;
        let body = document.body;
        body.classList.toggle("is-modalOpen");
      }
    },

    clickOutside2() {
      var body = document.body;
      if (!this.isSignUp) this.isLogin = false;
      if (!this.isSignUp && !this.isForgotPwd) body.classList.remove("is-modalOpen");
    },
    clickOutside1() {
      var body = document.body;
      if (!this.isLogin) this.isSignUp = false;
      if (!this.isLogin && !this.isForgotPwd) body.classList.remove("is-modalOpen");
    },
    onForgotLink() {
      this.isForgotPwd = true;
      this.isLogin = false;
      this.isSignUp = false;
    },
    onSignInLink() {
      this.isSignUp = false;
      this.isLogin = true;
    },
    onSignupLink() {
      this.isSignUp = true;
      this.isLogin = false;
    },
    onSave() {
      this.emailValid = true;
      this.$v.userEmail.$touch();
      if (this.$v.userEmail.$error) {
        return;
      }
      this.emailValid = false;
      this.isSubmitting = true;
      forgotPassword({ email: this.userEmail })
        .then((res) => {
          this.$notify({
            group: "notify",
            type: "success",
            text: res.data.message,
          });
          this.isForgotPwd = false;
          this.isSubmitting = false;
        })
        .catch((err) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: err.response.data.message,
          });
        });
    },
    userProfile() {
      this.$router.push("/profile");
      this.toggleDropdownMenu();
    },
    getContracts() {
      this.$router.push("/contracts");
      this.toggleDropdownMenu();
    },
    onInput() {
      this.emailValidate = "";
    },
    openModal(isLogin, isSignUp, isExplore = false, isForgotPwd) {
      this.isLogin = isLogin;
      this.isSignUp = isSignUp;
      this.isExplore = isExplore;
      this.isForgotPwd = isForgotPwd;

      var body = document.body;
      if (isSignUp) {
        setTimeout(function () {
          // console.log(123);
          // document.querySelector(".validate_number").addEventListener("keypress", function (evt) {
          //     if (
          //       (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
          //       evt.which > 57
          //     ) {
          //       evt.preventDefault();
          //     }
          //   });
        }, 0);
      }

      body.classList.toggle("is-modalOpen");
    },
    handleLogin() {
      this.loginValidate = true;
      let redirectTo = this.$router.history.current.query.redirectTo;
      // console.log("redirectTo", redirectTo);
      this.$v.loginForm.$touch();
      if (this.$v.loginForm.$error) {
        return;
      }
      this.loginValidate = false;
      this.loading = true;
      this.isSubmittingLogin = true;
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          this.$notify({
            group: "notify",
            type: "success",
            text: "Login successfully",
          });
          if (redirectTo) {
            this.$router.push({ path: redirectTo });
          }
          this.openModal();
          this.loading = false;
          this.isSubmittingLogin = false;
        })
        .catch((err) => {
          this.loading = false;

          err.response.data.error &&
            this.$notify({
              group: "notify",
              type: "error",
              text: err.response.data.error,
            });
          if (err.response.data.data) {
            this.$notify({
              group: "notify",
              type: "error",
              text: err.response.data.data.email[0],
            });
          }
          this.isSubmittingLogin = false;
        });
    },
    selectedFile(event) {
      this.signUpForm.profile_image = event.target.files[0];
      // console.log("dp", this.signUpForm.profile_image);
    },
    handleSignUp() {
      this.regValidate = true;
      this.$v.signUpForm.$touch();
      if (this.$v.signUpForm.$error) {
        return;
      }
      this.regValidate = false;
      this.loading = true;
      this.isSubmittingReg = true;
      // console.log("----------------------", this.signUpForm);
      var formData = new FormData();
      formData.append("name", this.signUpForm.name);
      formData.append("email", this.signUpForm.email);
      formData.append("password", this.signUpForm.password);

      // if (this.signUpForm.profile_image) {
      // console.log(this.defaultImg)
      // formData.append("profile_image", this.defaultImg);
      // } else {
      formData.append(
        "profile_image",
        this.signUpForm.profile_image !== undefined ? this.signUpForm.profile_image : ""
      );
      // }
      // console.log("formdata", formData);
      regUser(formData)
        .then((res) => {
          this.$notify({
            group: "notify",
            type: "success",
            // text: "A verification email has been sent to you. Please verify your email to login.",
            text: "Registered Successfully.",
            duration: 10000,
          });
          this.openModal();
          this.loading = false;
          this.isSubmittingReg = false;
        })
        .catch((err) => {
          if (err.response.data.data.email) {
            this.emailValidate = err.response.data.data.email[0];
          }
          this.loading = false;
          this.isSubmittingReg = false;
        });
    },
    redirectToAdmin() {
      // this.$router.push({ path: "/admin" });
      // setTimeout(() => {
      //   this.$router.go(this.$router.currentRoute);
      // }, 500);
      // this.$router.push({ path: '/admin', reload: true })
      window.location.href = `${this.$store.state.common.publicPath}admin`;
    },
    async handleLogout() {
      await this.$store.dispatch("user/logout");
      this.$notify({
        group: "notify",
        type: "success",
        text: "logout successfully",
      });
      this.$router.push(`/`);
    },
    toggleDropdownMenu() {
      var element = document.getElementById("nav_dropdown");
      if (element.classList) {
        element.classList.toggle("is-dropdownOpen");
      } else {
        // For IE9
        var classes = element.className.split(" ");
        var i = classes.indexOf("is-dropdownOpen");

        if (i >= 0) classes.splice(i, 1);
        else classes.push("is-dropdownOpen");
        element.className = classes.join(" ");
      }
    },
    closeDropdownMenu() {
      var element = document.getElementById("nav_dropdown");

      if (element.classList) {
        element.classList.remove("is-dropdownOpen");
      } else {
        // For IE9
        var classes = element.className.split(" ");
        var i = classes.indexOf("is-dropdownOpen");

        if (i >= 0) classes.splice(i, 1);
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  validations: {
    signUpForm: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      name: {
        required,
      },
    },
    loginForm: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
    userEmail: {
      required,
      email,
    },
  },
};
</script>

<style scoped>
/* General Utility Classes */
.mb-2 {
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.margin-term {
  margin-top: 0.5em;
}

/* Dropdown Toggle Styles */
.dropdown__toggle_white {
  color: #ffffff !important;
}

.dropdown__toggle_black {
  color: #000000 !important;
}

/* Signup Error Styles */
.signupError {
  margin-top: 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
  color: #ec5d70;
}

/* Error Message Styles */
.error {
  display: block;
  font-size: 13px;
  color: tomato;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.4s;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Text Color */
.black {
  color: black;
}

.white {
  color: white;
}

/* Hamburger Menu Styles */
nav {
  position: relative;
}

.mob-nav-links {
  position: fixed; /* Fixed positioning to center the menu */
  top: 25%;
  left: 50%;
  transform: translateX(-50%) translateY(-100%); /* Center horizontally and hide initially */
  width: 100%;
  max-width: 300px; /* Set a max-width for better appearance */
  background-color: #333; /* Background color for the nav menu */
  transition: transform 0.3s ease-in-out; /* Smooth slide effect */
  z-index: 1000; /* Ensure it's above other content */
  list-style: none;
  padding: 1rem;
  margin: 0;
}

/* Show the menu when nav is open */
.nav-open .mob-nav-links {
  transform: translateX(-50%) translateY(0); /* Slide the menu into view */
}

/* Style for navigation links */
.mob-nav-links li {
  margin: 0.5em 0;
}

.mob-nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em; /* Increase font size for better readability */
  display: block;
  padding: 0.5em 1em;
  transition: background-color 0.3s, color 0.3s; /* Smooth color transition */
}

.mob-nav-links a:hover {
  background-color: #555; /* Change background on hover */
  color: #fff;
}

.hamburger {
  font-size: 1.5em;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.nav-links ul {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  font-weight: bold;
  /* font-size: medium; */
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-links li:hover {
  color: #76b548;
}

.contact-us {
  list-style: none;
}

.contact-us button {
  background-color: #76b548;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: medium;
  cursor: pointer;
  text-transform: uppercase; /* Make text uppercase */
  transition: background-color 0.3s, color 0.3s;
}

.contact-us button:hover {
  background-color: #284858;
  color: white;
}

.hidden-w-1300 {
  display: none;
}
/* Responsive Styles */
@media (min-width: 1300px) {
  .hamburger {
    display: none;
  }
  .hamburger-remove {
    display: none;
  }
  .hidden-w-1300 {
    display: block;
  }

  .nav-links {
    display: flex;
  }

  .nav-links li {
    margin: 0 1em;
  }
}

.nav-open .nav-links {
  display: block;
}

/* Eye Icon Styles */
.fas.fa-eye,
.fa-eye-slash {
  top: 27px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.fieldFloating .formControl {
  height: 47px !important;
  padding: 20px 31px 5px 20px;
}
@media (min-width: 767px) {
  .hamburger-remove-xs {
    display: none;
  }
}
@media (max-width: 767px) {
  .hamburger-remove-xs-hide {
    display: none;
  }
}
.hamburger-remove-xs {
  margin-top: 20px;
}
.refine-btn {
  background-color: #268cab;
  padding: 10px 31px;
  /* height: 50px; */
  border-radius: 0px 6px 6px 0px;
  color: white;
  border: navajowhite;
  -webkit-box-shadow: 1px 1px 5px #b4b4b4;
  box-shadow: 1px 1px 5px #b4b4b4;
}
</style>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-title {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #888;
  cursor: pointer;
}

.popup-close:hover {
  color: #333;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-submit,
.form-close {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  color: white;
}

.form-submit {
  background-color: #007bff;
  margin-bottom: 10px;
}

.form-submit:hover {
  background-color: #0056b3;
}

.form-close {
  background-color: #ccc;
}

.form-close:hover {
  background-color: #999;
}
.border-add {
  /* border: 1px solid black; */
}
.popup-content[data-v-d556e1e6] {
  background-color: #f2f2f2;
}
.fieldFloating .formControl[data-v-d556e1e6] {
  height: 59px !important;
  padding: 20px 31px 5px 20px;
}
.submit-refine {
  padding: 10px 50px;
  background-color: #268cab;
  border-radius: 100px;
  color: white;
  border: none;
  margin-top: 20px;
}
.vdp-datepicker input[type="text"] {
  background-color: transparent !important;
}
.popup-title[data-v-d556e1e6] {
  font-size: 19px !important;
}
.fieldFloating .formControl {
  height: 70px !important;
  padding: 20px 31px 5px 20px !important;
}
.search-icon {
  background: white;
  padding: 16px;
  cursor: pointer;
  border-radius: 0px 4px 4px 0px;
}
.fieldFloating .formControl {
  border-radius: 4px 0px 0px 4px;
}
.fieldFloating {
  box-shadow: 0px 0px 21px #dcd7d7 !important;
  border-radius: 8px;
}
.header-sticky {
  position: sticky;
  z-index: 999;
  background: white;
  /* height: 129px; */
  top: 0px;
}
</style>
